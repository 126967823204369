export const translationEn = {
    "app_name": "Spark Dx",
    "home": "Home",
    "organizations": "Organizations",
    "pricing": "Pricing",
    "features": "Features",
    "admin": "Admin",
    "dashboard": "Dashboard",
    "view": "View",
    "login": "Login",
    "logout": "Logout",
    "sign_up": "Sign Up",
    "player_app_download_prompt": "You should download mobile app to be a player on this platform, do you wnat to go to download?",
    "username_empty": "Username is empty.",
    "email_empty": "Email is empty.",
    "password_empty": "Password is empty.",
    "password_not_match": "Password does not match.",
    "remove": "Remove",
    "coach": "Coach",
    "organizer": "Organizer",
    "players": "Players",
    "player": "Player",
    "staff": "Staff",
    "user": "User",
    "full_name": "Full Name",
    "your_name": "Your Name",
    "email": "Email",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "confirm_your_password": "Confirm your password",
    "agree_policy": "Agree the terms and policy",
    "invite_form_title": "You were invited as a patient from doctor.",
    "individuals": "Individuals",
    "retailers": "Retailers",
    "become_a_helper": "Become a helper",
    "book_delivery": "Book a delivery",
    "loading": "Loading",
    "photo_header_title": "Welcome to Spark Dx",
    "photo_header_text": "Visit Following Links to download our mobile app.",
    "get_a_quote": "Get a quote",
    "feature_title_1": "Tests",
    "feature_text_1": "Test instantly in 15 mins. No equipment needed",
    "feature_title_2": "Reports",
    "feature_text_2": "Monitor trends of cirtical results with Reports",
    "feature_title_3": "Doctors",
    "feature_text_3": "Doctors can access results of their registered patients",
    "learn_more": "Learn more",
    "explore_partnership": "Explore partnership",
    "service_title_1": "Your pocket lab. Recommended by Doctors",
    "service_text_1": "Experience diagnostics like never before with Spark. From health screening using self-tests to regular health checkups, keeping track of your health has never been this easy! Order the test kit, download the app and scan the results after taking the test. You will get your results in no time.",
    "income_title_1": "Start earning with Delivery. ",
    "income_text_1": "Build your own delivery business.",
    "income_action_1": "Earn $50/hr on average",
    "email_placeholder": "someone@email.com",
    "username": "Username",
    "username_placeholder": "Input your username",
    "password_placeholder": "Input your password",
    "retype": "Verify",
    "retype_placeholder": "Retype your password",
    "signup": "Sign Up",
    "remember_me": "Remember Me",
    "forgot_password": "Forgot Password",
    "send_reset_email": "Send Password Reset Email",
    "input_email_prompt": "You should input valid email address",
    "input_username_prompt": "You should input valid username",
    "input_password_prompt": "You should input valid password with at least 4 letters",
    "input_retype_prompt": "Password verifying string not matching",
    "reset_password_success": "Password reset email sent.",
    "reset_password_failure": "Failed to send password reset email",
    "auth_success": "User authenticated successfully",
    "auth_failure": "User authentication failed",
    "book_delivery_title": "Book a Delivery",
    "requred_fields_notation": "All fields required unless otherwise noted.",
    "customer_information": "Customer information",
    "firstname": "First Name",
    "firstname_placeholder": "Input your first name",
    "lastname": "Last Name",
    "lastname_placeholder": "Input your last name",
    "phone": "Phone Number",
    "phone_placeholder": "Input your phone number",
    "customer_note": "Note: Email & phone numbers are used to inform customers of delivery status. If either exist in our system already, we use the original record instead.",
    "locations": "Locations",
    "your_store": "Your store",
    "store_address": "Store Address",
    "store_name": "Store Name",
    "drop_off_address": "Drop-off address",
    "add_dropoff": "+ Add a drop off location",
    "items": "Items",
    "add_item": "+ Add Item",
    "this_is_store": "This is a store",
    "unit_number": "Unit or apartment number",
    "need_stairs": "Stairs",
    "hardwood_floors": "Has hardwood floors",
    "parking_info": "Parking and building info",
    "add_an_item": "Add an item",
    "add_item_note": "Select a popular item from this store",
    "helpers": "Helpers",
    "enter_item": "Enter an item",
    "item_helper_count": "Helper Count",
    "create_item": "Create an Item",
    "close": "Close",
    "add_order_id": "Add Order ID",
    "order_id": "Order No",
    "add_hfc_id": "Add HFC Id",
    "hfc_id": "HFC Id",
    "order_id_placeholder": "Enter Sales Order no.",
    "hfc_id_placeholder": "Enter HFC id.",
    "date_time": "Date & Time",
    "payment": "Payment",
    "deliveries": "Deliveries",
    "deliveries_completed": "Completed Deliveries",
    "delivery_completed": "Delivery completed",
    "delivery_not_completed": "Delivery not completed",
    "stores": "Stores",
    "name": "Name",
    "address": "Address",
    "create": "Create",
    "save": "Save",
    "edit": "Edit",
    "details": "Details",
    "cancel": "Cancel",
    "store_number": "Store Number",
    "change_success": "Data Change Uploaded Successfully.",
    "change_failure": "Data Change Update Failed.\n",
    "book_success": "Delivery Booked Successfully.",
    "book_failure": "Booking Delivery Failed.\n",
    "customer": "Customer",
    "store": "Store",
    "pick_store": "Pick Store",
    "how_many": "How many?",
    "book_date_title": "When will the order be ready for pick up?",
    "book_date_text": "The customer will be able to schedule the delivery on a later day if necessary.",
    "book_date_text_details": "This customer wants to schedule the delivery on date below",
    "book_date": "Del date 1",
    "book_date_label": "Delivery date",
    "book_time_label": "Delivery Time",
    "book_time_1_label": "Another Delivery Time",
    "full_assembly_title": "Add full Assembly for $25",
    "full_assembly_label": "Assembly Item",
    "full_assembly_text": "This item is eligible for full assembly. Please have assembly instructions ready for your Helper, and add any additional information in the Optional Details below",
    "breakdown_title": "Requires Breakdown to Move",
    "breakdown_text": "This is something that needs to be broken down and then reassembled after moving. if there are any special tools required please add in the Optional Details below",
    "add_photo_prompt": "If available, please add a photo receipt. Receipts help ensure our Helpers collect the right items.",
    "add_photo": "+ Add a Photo",
    "add_item_photo_prompt": "Please take pictures of the item front, back, top and bottom",
    "missing_item_photo_prompt": "You should take at least 4 pictures of the item you want to add",
    "add_item_photo": "+ Add a Photo",
    "item_photos": "Item Photos",
    "receipt_photo": "Receipt Photo",
    "add_optional_details": "+ Add Optional Details",
    "no_customer_data": "You should fill out customer data first.",
    "no_location": "You should pick the store and fill out drop off locations first.",
    "no_items": "You should add at least one item.",
    "no_order_id": "You should input a valid order id.",
    "take_photo": "Take A Photo",
    "take_receipt_photo": "Take a picture of the receipt",
    "take_item_photo": "Take a picture of the item",
    "width_placeholder": "Width (in.)",
    "height_placeholder": "Height (in.)",
    "depth_placeholder": "Depth (in.)",
    "weight_placeholder": "What do you think this weights? (lbs.)",
    "other_placeholder": "Please include anything else your Helper should know",
    "optional_notes": "Optional notes",
    "optional_notes_prompt": "Any special instructions for the helper(s) on this delivery?",
    "total_distance": "Total distance",
    "price": "Price",
    "pick_store_prompt": "You should pick store from the list by pressing Pick Store button",
    "np_deadline": "You should pick delivery date and time first.",
    "invalid_date_prompt": "You should pick date later than today.",
    "title": "Title",
    "delivery_detail": "Delivery Record Details",
    "index": "Item Index",
    "item_id": "Item Id",
    "invalid_deadline_0": "You can not pick time earlier than now",
    "invalid_deadline": "You can not pick ending time earlier than the start time for delivery",
    "clear": "Clear",
    "invalid_customer_email": "You should input valid customer email address.",
    "payment_success": "Paid Successful",
    "payment_failure": "Card Declined",
    "payment_no": "Not Paid",
    "delete": "Delete",
    "delivery_date_filter": "Delivery Date Range",
    "start": "From",
    "end": "To",
    "admin_login_prompt": "You need to login as admin account",
    "lot_config": "Lot Configuration",
    "lot_number": "Lot Number",
    "equation_config_params": "Equation config params",
    "config_param": "Config Parameter",
    "test_type": "Test Type",
    "spark_dx": "Spark Dx",
    "report": "Report",
    "reports": "Reports",
    "users": "Users",
    "login_with_google": "Login with Google",
    "patient_choice": "Patient Choice",
    "myself": "Myself",
    "blood_pressure": "Blood Pressure",
    "systolic": "Systolic",
    "diabolic": "Diabolic",
    "pulse": "Pulse",
    "urinalysis": "Urinalysis",
    "diet_tracker": "Diet Tracker",
    "test_amh": "AMH",
    "test_qvd": "QVD",
    "test_cr1": "CRP",
    "test_amts": "AMH",
    "test_vd": "Vitamin D",
    "test_tsh": "TSH",
    "test_bp": "Blood Pressure",
    "test_UTI": "UTI",
    "test_NAb": "CoVCheck",
    "test_Tst": "Testosterone",
    "test_Cort": "Cortisol",
    "test_Fer": "Ferritin",
    "test_CT2": "Cortisol Testosterone",
    "test_vd_range_title_01": "Deficient",
    "test_vd_range_title_02": "Insufficient",
    "test_vd_range_title_03": "Good",
    "test_qvd_range_title_01": "Deficient",
    "test_qvd_range_title_02": "Insufficient",
    "test_qvd_range_title_03": "Normal",
    "test_nab_range_title_01": "Negative",
    "test_nab_range_title_02": "Low +",
    "test_nab_range_title_03": "Medium +",
    "test_nab_range_title_04": "High + ",
    "test_vd_range_text_01": "Your Vitamin D level is Deficient. Please consult your doctor.",
    "test_vd_range_text_02": "Your Vitamin D level is Deficient. Please consult your doctor.",
    "test_vd_range_text_03": "It's all good",
    "test_qvd_range_text_01": "Your Vitamin D level is Deficient. Please consult your doctor.",
    "test_qvd_range_text_02": "Your Vitamin D level is Deficient. Please consult your doctor.",
    "test_qvd_range_text_03": "It's all good",
    "test_nab_range_text_01": "NAb levels are negative.  No Immunity against Covid",
    "test_nab_range_text_02": "NAb levels are Low.  Low Immunity against Covid",
    "test_nab_range_text_03": "NAb levels are Medium.  Medim immunity against Covid",
    "test_nab_range_text_04": "NAb levels are High.  High Immunity against Covid",
    "test_cr1_range_title_01": "Low Level",
    "test_cr1_range_title_02": "Medium Levels",
    "test_cr1_range_title_03": "High Level",
    "test_cr1_range_text_01": "Nice!  Low inflammation level",
    "test_cr1_range_text_02": "You have moderate inflammation.  Please consult your doctor",
    "test_cr1_range_text_03": "You have high inflammation. Please consult your doctor",
    "test_tsh_range_title_01": "Normal",
    "test_tsh_range_title_02": "High",
    "test_tsh_range_text_01": "Your TSH levels are normal",
    "test_tsh_range_text_02": "Your TSH levels are high",
    "test_tst_range_title_01": "Abnormal Low",
    "test_tst_range_title_02": "Normal",
    "test_tst_range_title_03": "Normal",
    "test_tst_range_text_01": "If you are a male, this level is low.  Please consult your doctor.",
    "test_tst_range_text_02": "If you are a male, this is Normal level.",
    "test_tst_range_text_03": "It's all good",
    "test_cort_range_title_01": "Low",
    "test_cort_range_title_02": "Normal",
    "test_cort_range_title_03": "High",
    "test_cort_range_text_01": "Cortisol levels are low.  Please consult your doctor.",
    "test_cort_range_text_02": "Cortisol levels are normal.  Keep it up!",
    "test_cort_range_text_03": "Cortisol levels are high.  Please consult your doctor.",
    "test_fer_range_title_01": "Low",
    "test_fer_range_title_02": "Normal",
    "test_fer_range_title_03": "High",
    "test_amh_range_title_01": "Low",
    "test_amh_range_title_02": "Normal",
    "test_amh_range_title_03": "High",
    "test_fer_range_text_01": "Ferritin levels are low.  Please consult your doctor.",
    "test_fer_range_text_02": "Ferritin levels are normal.  Keep it up!",
    "test_fer_range_text_03": "Ferritin levels are high.  Please consult your doctor.",
    "test_amh_range_text_01": "AMH levels are low.  Please consult your doctor.",
    "test_amh_range_text_02": "AMH levels are normal",
    "test_amh_range_text_03": "AMH levels are high.  Please consult your doctor.",
    "report_table": "Report Table",
    "report_graph": "Report Graph",
    "mode": "Mode",
    "all": "All",
    "latest_1_month": "Latest 1 month",
    "latest_2_months": "Latest 2 months",
    "latest_3_months": "Latest 3 months",
    "period": "Period",
    "no_data": "No Data",
    "_": "_",
}