import { t } from 'i18next';
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { userActions } from 'store/actions';
import { Col, Row, Button, Spinner, Input } from "reactstrap";
import { invokeHttpsApi } from "utils/API";


function LotConfig(props) {

    const { user } = props;

    const [lotChoice, setLotChoice] = useState(null)
    const [lotConfig, setLotConfig] = useState({})
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(false)

    useEffect(() => {
        loadContents()
        return () => {
            clearVariables()
        };
    }, [])

    const loadContents = () => {
        setLoading(true);
        invokeHttpsApi("data-queryLotConfig", {}, (data) => {
            if (data.success) {
                const update = {};
                if (data.lotConfig) {
                    for (const [k,v] of Object.entries(data.lotConfig)) {
                        update[k] = v;
                    }
                }
                setLotConfig(update);
            }
            setLoading(false);
        }, (error) => {
            console.log("Loading Contents Failed: ", error);
            setLoading(false);
        })
    }

    const saveConfig = () => {
        setProgress(true);
        invokeHttpsApi("data-submitLotConfig", {
            "lotConfig": lotChoice
        }, (data) => {
            if (data.success) {
                lotConfig[lotChoice.lotNum] = lotChoice;
                setLotConfig(lotConfig);
            }
            setProgress(false);
        }, (error) => {
            console.log("Saving Contents Failed: ", error);
            setProgress(false);
        })
    }

    const clearVariables = () => {

    }

    const renderLotConfig = () => {

        if (lotChoice) {
            const update = {};
            for (const [k,v] of Object.entries(lotChoice)) {
                update[k] = v;
            }
            return (
                <div className='info-tile admin-edit-content'>
                    <div className='input-item'>
                        <h4 className='input-label'>{t('lot_number')}</h4>
                        <Input type="text" className='form-control' placeholder={t('lot_number')} name="lotNum" id="lotNum" onChange={e => {
                            update.lotNum = e.target.value.toString();
                            setLotChoice(update)
                        }} value={lotChoice.lotNum ? lotChoice.lotNum : ""} />
                    </div>
                    <div className='input-item'>
                        <h4 className='input-label'>{t('test_type')}</h4>
                        <Input type="select" className='form-control' placeholder={t('test_type')} name="testType" id="testType" onChange={e => {
                            update.testType = e.target.value === "" ? null : e.target.value;
                            setLotChoice(update)
                        }} value={lotChoice.testType ? lotChoice.testType : ""}>
                            <option value=""></option>
                            <option value="qVitD">QVD</option>
                            <option value="CRP">CRP</option>
                            <option value="amts">AMTS</option>
                            <option value="AMH">AMH</option>
                        </Input>
                    </div>
                    <div className='input-item'>
                        <h4 className='input-label'>{t('equation_config_params')} 1</h4>
                        <Row style={{maring: 0}}>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 0</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config1) {
                                        update.config1 = {
                                            id: "config1",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config1.config0 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config1 && lotChoice.config1.config0 ? lotChoice.config1.config0 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 1</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config1) {
                                        update.config1 = {
                                            id: "config1",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config1.config1 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config1 && lotChoice.config1.config1 ? lotChoice.config1.config1 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 2</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config1) {
                                        update.config1 = {
                                            id: "config1",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config1.config2 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config1 && lotChoice.config1.config2 ? lotChoice.config1.config2 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 3</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config1) {
                                        update.config1 = {
                                            id: "config1",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config1.config3 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config1 && lotChoice.config1.config3 ? lotChoice.config1.config3 : 0} />
                            </Col>
                        </Row>
                        <h4 className='input-label'>{t('equation_config_params')} 2</h4>
                        <Row style={{maring: 0}}>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 0</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config2) {
                                        update.config2 = {
                                            id: "config2",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config2.config0 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config2 && lotChoice.config2.config0 ? lotChoice.config2.config0 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 1</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config2) {
                                        update.config2 = {
                                            id: "config2",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config2.config1 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config2 && lotChoice.config2.config1 ? lotChoice.config2.config1 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 2</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config2) {
                                        update.config2 = {
                                            id: "config2",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config2.config2 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config2 && lotChoice.config2.config2 ? lotChoice.config2.config2 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 3</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config2) {
                                        update.config2 = {
                                            id: "config2",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config2.config3 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config2 && lotChoice.config2.config3 ? lotChoice.config2.config3 : 0} />
                            </Col>
                        </Row>
                        <h4 className='input-label'>{t('equation_config_params')} 3</h4>
                        <Row style={{maring: 0}}>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 0</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config3) {
                                        update.config3 = {
                                            id: "config3",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config3.config0 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config3 && lotChoice.config3.config0 ? lotChoice.config3.config0 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 1</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config3) {
                                        update.config3 = {
                                            id: "config3",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config3.config1 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config3 && lotChoice.config3.config1 ? lotChoice.config3.config1 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 2</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config3) {
                                        update.config3 = {
                                            id: "config3",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config3.config2 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config3 && lotChoice.config3.config2 ? lotChoice.config3.config2 : 0} />
                            </Col>
                            <Col sm={6} lg={3}>
                                <h4 className='input-label-small'>{t('config_param')} 3</h4>
                                <Input type="number" step={0.001} className='form-control' placeholder={t('lot_number')} onChange={e => {
                                    if (!update.config3) {
                                        update.config3 = {
                                            id: "config3",
                                            config0: 0,
                                            config1: 0,
                                            config2: 0,
                                            config3: 0,
                                        }
                                    }
                                    update.config3.config3 = parseFloat(e.target.value);
                                    setLotChoice(update)
                                }} value={lotChoice.config3 && lotChoice.config3.config3 ? lotChoice.config3.config3 : 0} />
                            </Col>
                        </Row>
                        <div className='wrap-content-parent input-item'>
                            <div className="wrap-content-fill-child" style={{marginRight: 5}} />
                            {progress ? <Spinner color="primary" /> : 
                            <Button className="btn-blue" style={{margin: '5px 5px'}} onClick={e=> {
                                saveConfig();
                            }}>{t('save')}</Button>}
                            <Button className="btn-cancel" style={{margin: '5px 10px'}} onClick={e=> {
                                setLotConfig(null);
                            }}>{t('close')}</Button>
                        </div>
                    </div>
                    
                </div>
            )
        }
        const itemArr = [];
        
        for (const item of Object.values(lotConfig)) {
            itemArr.push(
                <Row style={{margin: 0}}>
                    <Col sm={2} xs={3} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <h4 className='info-label'>
                            {item.testType}
                            </h4>
                        </div>
                    </Col>
                    <Col sm={7} xs={6} className="vertical-center" style={{padding: 5}}>
                        <h4 className='info-label'>
                        {item.lotNum}
                        </h4>
                    </Col>
                    <Col sm={3} xs={3} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child" style={{marginRight: 5}} />
                            <Button className="btn-cancel" style={{margin: '5px 0px'}} onClick={e=> {
                                setLotChoice(item);
                            }}>{t('edit')}</Button>
                        </div>
                    </Col>
                </Row>
            );
            itemArr.push(<div className='divider' />);
        }
        
        return (
            <div className='info-tile'>
                <Row style={{margin: 0}}>
                    <Col sm={3} className="vertical-center" style={{padding: 5}}>
                        <h4 className='info-label'>
                        {t('lot_config')}
                        </h4>
                    </Col>
                    <Col sm={9} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child" style={{marginRight: 5}} />
                            <Button className="btn-cancel" style={{margin: '5px 0px'}} onClick={e=> {
                                setLotChoice({})
                            }}>{t('create')}</Button>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {loading ? (
                    <div className="dashboard-content-empty">
                    <Spinner color="primary" />
                    </div>
                    ) : (itemArr.length > 0 ? itemArr : (
                    <div className="dashboard-content-empty">
                    No Data
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div>
            {user && user.isAdmin && renderLotConfig()}
        </div>
    )
}

function mapState(state) {
    const { user, loggingIn } = state.auth;

    return { user, loggingIn };
}

const actionCreators = {
    register: userActions.register,
    login: userActions.login,
    logout: userActions.logout,
    sendResetEmail: userActions.sendResetEmail,
}

export default connect(mapState, actionCreators) (LotConfig)