export const MODE_QVD = "qVitD";
export const MODE_CR1 = "CRP";
export const MODE_AMTS = "amts";
export const MODE_AMH = "AMH";
export const MODE_TSH = "TSH";
export const MODE_VD = "VitD";
export const MODE_NAb = "NAb";
export const MODE_UTI = "UTI";
export const MODE_Tst = "Testosterone";
export const MODE_Cort = "Cortisol";
export const MODE_Fer = "Ferritin";
export const MODE_CT2 = "CT Dual";
export const MODE_GC = "Glucose";
export const MODE_BP = "Pressure";
export const MODE_U10A = "U10A";
export const MODE_U5B = "U5B";
export const MODE_U1 = "Diet Tracker U1";
export const MODE_U2 = "Diet Tracker U2";
export const MODE_P5 = "P5";
export const MODE_P9 = "O9";
export const MODE_O1 = "O1";
export const MODE_O1B = "O1B";
export const MODE_U2KA = "U2KA";
export const MODE_U1K = "U1K";
export const MODE_TSH2 = "iTSH";
export const MODE_Fer2 = "iFerritin";
export const MALE = "Male";
export const FEMALE = "Female";

export const colorBlue = "#73B0DB"
export const colorBlueBackground = "#DEF0FA"
export const colorPastelBlue = "#ccdced"
export const colorPastelOrange = "#ffeadd"
export const colorPastelGreen = "#e5ffcc"
export const colorPastelPurple = "#dcd0dc"
export const colorPastelYellow = "#fcfad6"
export const colorPastelGrey = "#f5f5f5"

export const REPORT_MODE_ARRAY = [
    MODE_VD,
    MODE_CR1,
    MODE_TSH,
    MODE_Tst,
    MODE_Cort,
    MODE_Fer,
    MODE_UTI,
    MODE_QVD,
    MODE_AMH,
    MODE_CT2,
    MODE_BP,
]

export const DUAL_MODE_ARRAY = [
    MODE_CT2, 
]

export const BP_MODE_ARRAY = [
    MODE_BP, 
    MODE_GC,
]

export const URINE_MODE_ARRAY = [
    MODE_U1, 
    MODE_U2,
    MODE_UTI,
    MODE_U10A,
    MODE_U5B,
    MODE_P5,
    MODE_P9,
    MODE_U2KA,
    MODE_O1B,
    MODE_O1,
    MODE_U1K,
]

export const isDualType = (type) => {
    return DUAL_MODE_ARRAY.includes(type)
}

export const isBpType = (type) => {
    return BP_MODE_ARRAY.includes(type)
}

export const isUrineType = (type) => {
    return URINE_MODE_ARRAY.includes(type)
}

export const getModeTitleId = (type) => {
    switch (type) {
        case MODE_VD:
            return "test_vd";
        case MODE_TSH:
        case MODE_TSH2:
            return "test_tsh";
        case MODE_Tst:
            return "test_Tst";
        case MODE_CR1:
            return "test_cr1";
        case MODE_Cort:
            return "test_Cort";
        case MODE_Fer:
        case MODE_Fer2:
            return "test_Fer";
        case MODE_CT2:
            return "test_CT2";
        case MODE_AMH:
            return "test_amh";
        case MODE_NAb:
            return "test_NAb";
        case MODE_QVD:
            return "test_qvd";
        case MODE_AMTS:
            return "test_amts";
        case MODE_U1:
        case MODE_U2:
        case MODE_U1K: 
            return "diet_tracker";
        case MODE_U5B:
        case MODE_U10A:
        case MODE_UTI: 
            return "urinalysis";
        case MODE_BP:
        case MODE_GC: 
            return "test_bp";
        default:
            return "unknown";
    }
}

export const getModeUnit = (type) => {
    switch (type) {
        case MODE_VD: return "ng/ml"
        case MODE_CR1: return "μg/ml"
        case MODE_TSH: case MODE_TSH2: return "μIU/mL"
        case MODE_Tst: return "ng/ml"
        case MODE_Cort: return "ng/ml"
        case MODE_Fer: case MODE_Fer2: return "ng/ml"
        case MODE_CT2: return "ng/ml"
        case MODE_AMH: case MODE_AMTS: return "ng/ml"
        case MODE_NAb: return "BAU/mL"
        case MODE_QVD: return "ng/ml"
        default: return "unit"
    }
}

export const getModeSecModeUnit = (type) => {
    switch (type) {
        case MODE_VD: return "nmol/L"
        case MODE_Tst: return "nmol/L"
        case MODE_Cort: return "nmol/L"
        case MODE_Fer: case MODE_Fer2: return "nmol/L"
        case MODE_NAb: return "BAU/mL"
        case MODE_TSH: case MODE_TSH2: return "μIU/mL"
        case MODE_AMH: return "pmol/L"
        case MODE_CR1: return "nmol/L"
        case MODE_QVD: return "nmol/L"
        default: return null;
    }
}

export const getModeSecCalc = (type) => {
    switch (type) {
        case MODE_VD: return (input) => { return input * 2.496}
        case MODE_Tst: return (input) => { return input * 3.467}
        case MODE_Cort: return (input) => { return input * 2.7585}
        case MODE_Fer: case MODE_Fer2: return (input) => { return input *  2.25}
        case MODE_NAb: return (input) => { return input}
        case MODE_TSH: case MODE_TSH2: return (input) => { return input}
        case MODE_AMH: return (input) => { return input * 7.15}
        case MODE_CR1: return (input) => { return input * 9.52}
        case MODE_QVD: return (input) => { return input * 2.5}
        default: return null;
    }
}

export const getModeRangeTextRes = (type) => {
    switch (type) {
    case MODE_VD:
        return [
            "test_vd_range_text_01",
            "test_vd_range_text_02",
            "test_vd_range_text_03",
            ]
    case MODE_CR1:
        return [
            "test_cr1_range_text_01",
            "test_cr1_range_text_02",
            "test_cr1_range_text_03",
            ]
    case MODE_TSH: case MODE_TSH2:
        return [
            "test_tsh_range_text_01",
            "test_tsh_range_text_02",
            ]
    case MODE_Tst:
        return [
            "test_tst_range_text_01",
            "test_tst_range_text_02",
            "test_tst_range_text_03",
            ]
    case MODE_Cort:
        return [
            "test_cort_range_text_01",
            "test_cort_range_text_02",
            "test_cort_range_text_03",
            ]
    case MODE_Fer: case MODE_Fer2:
        return [
            "test_fer_range_text_01",
            "test_fer_range_text_02",
            "test_fer_range_text_03",
            ]
    case MODE_AMH: case MODE_AMTS:
        return [
            "test_amh_range_text_01",
            "test_amh_range_text_02",
            "test_amh_range_text_03",
            ]
    case MODE_NAb:
        return [
            "test_nab_range_text_01",
            "test_nab_range_text_02",
            "test_nab_range_text_03",
            "test_nab_range_text_04",
            ]
    case MODE_QVD:
        return [
            "test_qvd_range_text_01",
            "test_qvd_range_text_02",
            "test_qvd_range_text_03",
            ]
    default:
        return []
    }
}

export const getModeRangeTitleRes = (type) => {
    switch (type) {
        case MODE_VD:
            return [
                "test_vd_range_title_01",
                "test_vd_range_title_02",
                "test_vd_range_title_03",
                ]
        case MODE_CR1:
            return [
                "test_cr1_range_title_01",
                "test_cr1_range_title_02",
                "test_cr1_range_title_03",
                ]
        case MODE_TSH: case MODE_TSH2:
            return [
                "test_tsh_range_title_01",
                "test_tsh_range_title_02",
                ]
        case MODE_Tst:
            return [
                "test_tst_range_title_01",
                "test_tst_range_title_02",
                "test_tst_range_title_03",
                ]
        case MODE_Cort:
            return [
                "test_cort_range_title_01",
                "test_cort_range_title_02",
                "test_cort_range_title_03",
                ]
        case MODE_Fer: case MODE_Fer2:
            return [
                "test_fer_range_title_01",
                "test_fer_range_title_02",
                "test_fer_range_title_03",
                ]
        case MODE_AMH: case MODE_AMTS:
            return [
                "test_amh_range_title_01",
                "test_amh_range_title_02",
                "test_amh_range_title_03",
                ]
        case MODE_NAb:
            return [
                "test_nab_range_title_01",
                "test_nab_range_title_02",
                "test_nab_range_title_03",
                "test_nab_range_title_04",
                ]
        case MODE_QVD:
            return [
                "test_qvd_range_title_01",
                "test_qvd_range_title_02",
                "test_qvd_range_title_03",
                ]
        default: return []
    }
}

export const getModePoint = (type) => {
    switch (type) {
        case MODE_BP:
            return 0
        case MODE_TSH: case MODE_TSH2:
            return 2
        case MODE_Tst:
            return 2
        case MODE_CR1:
            return 2
        case MODE_Cort:
            return 2
        case MODE_Fer: case MODE_Fer2:
            return 2
        case MODE_CT2:
            return 4
        case MODE_AMH: case MODE_AMTS:
            return 2
        case MODE_NAb:
            return 2
        case MODE_QVD:
            return 2
        default:
            return 2
    }
}

export const getRangeValues = (type, sexual) => {
    switch (type) {
        case MODE_VD:
            return [
                0.0,
                10.0,
                30.0,
                100.0
            ]
        case MODE_CR1:
            return [
                0.0,
                10.0,
                40.0,
                100.0,
            ]
        case MODE_TSH: case MODE_TSH2:
            return [
                0.0,
                5.0,
                7.0,
            ]
        case MODE_Tst:
            return [
                0.0,
                sexual === MALE ? 3.0 : 0.2,
                sexual === MALE ? 10.0 : 0.8,
                sexual === MALE ? 25.0 : 5.0,
            ]
        case MODE_Cort:
            return [
                0.0,
                sexual === MALE ? 50.0 : 30.0,
                sexual === MALE ? 250.0 : 130.0,
                1200.0,
            ]
        case MODE_CT2:
            return [
                0.0,
                sexual === MALE ? 3.0 : 0.2,
                sexual === MALE ? 10.0 : 0.8,
                sexual === MALE ? 25.0 : 5.0,
            ]
        case MODE_Fer: case MODE_Fer2:
            return [
                0.0,
                sexual === MALE ? 24.0 : 11.0,
                sexual === MALE ? 336.0 : 307.0,
                500.0,
            ]
        case MODE_AMH: case MODE_AMTS:
            return [
                0.0,
                1.0,
                7.0,
                16.0,
            ]
        case MODE_NAb:
            return [
                25.0,
                50.0,
                250.0,
                500.0,
                2500.0,
            ]
        case MODE_QVD:
            return [
                0.0,
                10.0,
                30.0,
                100.0,
                ]
        default: return []
    }
}

export const getPrimaryColor = (type) => {
    switch (type) {
        case MODE_VD: return "#FF7F41"
        case MODE_CR1: return "#FF2600"
        case MODE_TSH: case MODE_TSH2: return "#026990"
        case MODE_Tst: return "#286140"
        case MODE_Cort: return "#286140"
        case MODE_Fer: case MODE_Fer2: return "#286140"
        case MODE_CT2: return "#286140"
        case MODE_AMH: case MODE_AMTS: return "#9F325A"
        case MODE_NAb: return "#FF7F41"
        case MODE_QVD: return "#286140"
        default: return "black";
    }
}

export const getSecondaryColor = (type) => {
    switch (type) {
        case MODE_VD: return "#FFBEAD"
        case MODE_CR1: return "#FFBAD3"
        case MODE_TSH: case MODE_TSH2: return "#D8E7F8"
        case MODE_Tst: return "#F0FFD7"
        case MODE_Cort: return "#F0FFD7"
        case MODE_Fer: case MODE_Fer2: return "#F0FFD7"
        case MODE_CT2: return "#F0FFD7"
        case MODE_AMH: case MODE_AMTS: return "#F6DFEF"
        case MODE_NAb: return "#FFBEAD"
        case MODE_QVD: return "#F0FFD7"
        default: return "#F0FFD7"
    }
}

export const getPrimaryBgColor = (type) => {
    switch (type) {
        case MODE_TSH: case MODE_TSH2: return colorPastelBlue
        case MODE_VD: case MODE_NAb: return colorPastelOrange
        case MODE_Cort: case MODE_Tst: case MODE_Fer: case MODE_Fer2: case MODE_QVD: case MODE_CT2: return colorPastelGreen
        case MODE_U1: case MODE_U2: case MODE_U1K: case MODE_CR1: return colorPastelPurple
        case MODE_U5B: case MODE_U10A: case MODE_UTI: return colorPastelYellow
        default: return colorPastelGrey
    }
}

export const getSecondaryBgColor = (type) => {
    switch (type) {
        case MODE_TSH: case MODE_TSH2: return colorPastelBlue
        case MODE_VD: case MODE_NAb: return colorPastelOrange
        case MODE_Cort: case MODE_Tst: case MODE_Fer: case MODE_Fer2: case MODE_QVD: case MODE_CT2: return colorPastelGreen
        case MODE_U1: case MODE_U2: case MODE_U1K: case MODE_CR1: return colorPastelOrange
        case MODE_U5B: case MODE_U10A: return colorPastelOrange
        default: return colorPastelGrey
    }
}

export const getModeStatusRes = (item) => {
    const ranged = getRangeValues(item.type);
    const titles = getModeRangeTitleRes(item.type);
    if (ranged.length > 0) {
        var index = 0
        for (var i = 0; i < ranged.length; i ++) {
            if (item.average && item.average > ranged[i]) {
                index = i;
            }
            
            if (index >= titles.length) {
                index = titles.length - 1
            }

            return titles[index]
        }
    }
    return "unknown"
}